import React, {useEffect, useRef} from 'react'
import styled from 'styled-components';
import gsap from 'gsap'

import RowWrapper from '../utils/rowWrapper';

import Layout from '../components/layout';

import SentIcon from '../images/email-sent.png'
 
const Container = styled.div`
 padding-top: 8rem;

 @media ${props => props.theme.breaks.down('md')} {
  padding-top: 3rem;
 }

 .attrib {
   font-size: .8rem;
   position: absolute;
   bottom: 0;
   z-index: 10;
   right: 5px;
   color: #ddd;

   @media ${props => props.theme.breaks.down('sm')} {
    z-index: 0;
   }
 }
`

const Title = styled.div`
  margin: 3rem auto;
   h1 {
     text-align: center;
     font-size: 3rem;
     font-weight: normal;

     @media ${props => props.theme.breaks.down('md')} {
      font-size: 2rem;
     }
   }
`

const Subtext = styled.p`
  font-size: 1.6rem;
  text-align: center;
`

const Main = styled.section`
  /* min-height: 30rem; */
  margin: 0 auto;
  /* margin-top: 5rem; */

  @media ${props => props.theme.breaks.down('md')} {
    
  }
`

const Image = styled.div`
  width: 10rem;
  height: 10rem;
  margin: 2rem auto;

 img {
   width: 100%;
   object-fit: contain;
   transform: rotateZ(45deg);
 }

 @media ${props => props.theme.breaks.down('md')} {
  /* width: 70%; */
  /* padding: 2rem 0; */
 }
`

const Success = ({location}) => {

 const imageRef = useRef()

 useEffect(() => {
  gsap.fromTo(imageRef.current, {autoAlpha: .8, scale: .8}, { autoAlpha: 1, scale: 1, rotate: 360, delay: .5, duration: 2})
 }, [])

 return (
  <Layout location={location.pathname} pageTitle="Novosft - Message Submitted!">
  <Container>
    <RowWrapper>
      <Main>
        <Title>
          <h1>Thank you for contacting us.</h1>
        </Title>

        <Image>
          <img ref={imageRef} src={SentIcon} />
        </Image> 

        <Subtext>
          We will get back to you shortly.
        </Subtext>
      </Main>
    
    </RowWrapper>
    <a className="attrib" href='https://pngtree.com/so/aircraft'>aircraft png from pngtree.com</a>
  </Container>
 </Layout>
 )
}

export default Success;